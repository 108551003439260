import { IHumanName } from "@ahryman40k/ts-fhir-types/lib/R4";
import { nameCase } from "@foundernest/namecase";
import cloneDeep from "lodash/cloneDeep";

const usePriority = ["usual", "official", null, "maiden", "old", "nickname", "temp", "anonymous"];

export function getBinaryFromFhirXml(
  xml: string
): { data: string; contentType: string } | undefined {
  // Parse the XML string into an XML document
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "application/xml");

  // Define the FHIR namespace
  const FHIR_NS = "http://hl7.org/fhir";

  // Use XPath to find the Binary entry
  const binaryNode = xmlDoc.evaluate(
    "//f:Binary",
    xmlDoc,
    (prefix) => (prefix === "f" ? FHIR_NS : null),
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  ).singleNodeValue as Element;

  if (!binaryNode) {
    // eslint-disable-next-line no-console
    console.log("No binary resource found in FHIR+XML document");
    return;
  }

  // Extract contentType
  const contentTypeNode = binaryNode.getElementsByTagName("contentType")[0];
  const contentType = contentTypeNode?.getAttribute("value");

  if (!contentType) {
    // eslint-disable-next-line no-console
    console.log("Binary node has no content type");
    return;
  }

  // Extract and decode the Base64 data
  const dataNode = binaryNode.getElementsByTagName("data")[0];
  if (!dataNode) {
    // eslint-disable-next-line no-console
    console.log("Binary node has no data");
    return;
  }

  const data = dataNode.getAttribute("value");
  if (!data) {
    // eslint-disable-next-line no-console
    console.log("Binary node has no data");
    return;
  }

  return { data, contentType };
}

export function getBinaryFromFhirJson(
  json: string
): { data: string; contentType: string } | undefined {
  // Parse the JSON string into a JSON object
  const fhirObject = JSON.parse(json);

  // Extract the Binary resource
  const binaryResource = fhirObject.entry.find(
    (entry: any) => entry.resource.resourceType === "Binary"
  );

  if (!binaryResource) {
    // eslint-disable-next-line no-console
    console.log("No binary resource found in FHIR+JSON document");
    return;
  }

  // Extract contentType
  const contentType = binaryResource.resource.contentType;

  if (!contentType) {
    // eslint-disable-next-line no-console
    console.log("Binary resource has no content type");
    return;
  }

  // Extract and decode the Base64 data
  const data = binaryResource.resource.data;
  if (!data) {
    // eslint-disable-next-line no-console
    console.log("Binary node has no data");
    return;
  }

  return { data, contentType };
}

/**
 * Sort FHIR human names in place by relevance of the use property.
 * @param names IHumanName[]
 * @returns IHumanName[]
 */
function sortNames(names: IHumanName[]): IHumanName[] {
  return names.sort((a: IHumanName, b: IHumanName) => {
    const priorityA = usePriority.findIndex((use) => use === a.use);
    const priorityB = usePriority.findIndex((use) => use === b.use);
    return priorityA - priorityB;
  });
}

export function namesToString(names: IHumanName[] | undefined): string {
  if (!names || !names.length) {
    return "";
  }

  const sortedNames = sortNames(cloneDeep(names));
  const givenNames = sortedNames.find((name) => !!name.given?.length)?.given;
  const familyName = sortedNames.find((name) => !!name.family)?.family;

  const displayNames = [];

  givenNames?.forEach((name) => {
    if (name) {
      displayNames.push(name);
    }
  });

  if (familyName) {
    displayNames.push(familyName);
  }

  return displayNames
    .map((name) => nameCase(name))
    .join(" ")
    .trim();
}
