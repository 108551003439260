import { RouteConfig } from "vue-router";

import { Role } from "@/global";

const documentAccessRoutes: RouteConfig[] = [
  {
    path: "",
    name: "documentAccess",
    meta: {
      title: "meta.documentAccess",
      authorize: [Role.Patient, Role.Representative],
      tile: "documentAccess",
    },
    component: () =>
      import(
        /* webpackChunkName: "documentAccess" */ "@/components/documentAccess/DocumentAccessTile.vue"
      ),
  },
  {
    path: "search",
    name: "documentAccessSearch",
    meta: {
      title: "meta.documentAccessSearch",
      authorize: [],
      tile: "documentAccess",
    },
    component: () =>
      import(
        /* webpackChunkName: "documentAccess" */ "@/components/documentAccess/ProviderSearch.vue"
      ),
  },
  {
    path: "new",
    name: "documentAccessDetails",
    meta: {
      title: "meta.documentAccessDetails",
      authorize: [],
      tile: "documentAccess",
    },
    component: () =>
      import(
        /* webpackChunkName: "documentAccess" */ "@/components/documentAccess/DocumentAccessDetailsTile.vue"
      ),
  },
  {
    path: ":id/edit",
    name: "documentAccessDetailsEdit",
    meta: {
      title: "meta.documentAccessDetailsEdit",
      authorize: [],
      tile: "documentAccess",
    },
    component: () =>
      import(
        /* webpackChunkName: "documentAccess" */ "@/components/documentAccess/DocumentAccessDetailsTile.vue"
      ),
  },
];

export default documentAccessRoutes;
