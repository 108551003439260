
import Button from "primevue/button";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import Tile from "@/components/Tile.vue";
import GenderIcon from "@/components/icons/GenderIcon.vue";
import { IPatient } from "@/global";
import ViewModule from "@/store/modules/view";

@Component({
  components: {
    Tile,
    Button,
    GenderIcon,
  },
})
export default class PurePatientTile extends Vue {
  @Prop({}) readonly patient!: IPatient;
  @Prop({ default: false }) private readonly hasEmergencyAccess!: boolean;
  private readonly viewState = getModule(ViewModule);

  get dateOfBirth(): string {
    return this.patient.dateOfBirth?.format("DD.MM.YYYY") || "";
  }

  get genderLabel(): string | null {
    if (this.patient.gender) {
      return this.$t(`valueSets.DocumentEntry.gender.${this.patient.gender}`).toString();
    }
    return null;
  }

  get name(): string {
    return this.patient.name;
  }

  @Emit("documentAccessTriggered")
  documentAccessTriggered() {}

  @Emit("logOutPatient")
  logOutPatient() {}
}
