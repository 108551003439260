export function base64ToBlob(base64Data: string, mimeType: string): Blob {
  // Convert Base64 string to a Blob
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

export function printBlob(blob: Blob) {
  // Create a Blob URL
  const objectUrl = URL.createObjectURL(blob);

  // Create a hidden iframe
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  document.body.appendChild(iframe);

  const afterPrintHandler = () => {
    document.body.removeChild(iframe);
    URL.revokeObjectURL(objectUrl);
    window.removeEventListener("afterprint", () => afterPrintHandler);
  };
  window.addEventListener("afterprint", () => afterPrintHandler);

  // Load PDF and trigger print
  iframe.src = objectUrl;
  iframe.onload = () => {
    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();
  };
}

export function printIframe(id: string, afterPrint?: () => void) {
  const iframe = document.getElementById(id) as HTMLIFrameElement;

  if (!iframe) {
    return;
  }

  const iframeWindow = iframe.contentWindow;

  if (!iframeWindow) {
    // eslint-disable-next-line no-console
    console.error("Could not access iframe content.");
    return;
  }

  // Detect when the print dialog closes
  const afterPrintHandler = () => {
    if (afterPrint) {
      afterPrint();
    }
    window.removeEventListener("afterprint", afterPrintHandler);
  };

  window.addEventListener("afterprint", afterPrintHandler);

  // Trigger print
  iframeWindow.focus();
  iframeWindow.print();
}

export function printBase64(base64Data: string, mimeType: string) {
  const blob = base64ToBlob(base64Data, mimeType);
  printBlob(blob);
}

export function downloadBase64(base64Data: string, contentType: string, fileName: string) {
  const dataUrl = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = dataUrl;
  downloadLink.download = fileName;
  downloadLink.click();
}
