export function getInitials(fullName: string) {
  const initials = fullName.split(" ").map((name) => name[0]);
  if (!initials) {
    return "";
  }

  if (initials.length > 2) {
    return `${initials[0]}${initials.slice(-1)}`.toUpperCase();
  }

  return initials.join("").toUpperCase();
}

export function getShortName(fullName: string, maxLength = 20) {
  if (!fullName) {
    return "";
  }

  let returnName = fullName;

  const names = fullName.replaceAll(/\s+/g, " ").split(" ");
  if (!names) {
    return "";
  }

  let initials = "";
  if (names.length > 2) {
    initials = names
      .slice(1, -1)
      .map((middleName) => `${middleName[0]}.`)
      .join(" ");
    returnName = `${names[0]} ${initials} ${names.slice(-1)}`;
  }

  if (returnName.length > maxLength) {
    returnName = `${names[0][0]}. ${initials} ${names.slice(-1)}`;
  }

  return returnName;
}
